import './SectionHeading.css';

function SectionHeading({title}) {
    return (
        <div id="sectionheading" >
            {title}
        </div>
    );

}

export default SectionHeading;